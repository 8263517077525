<template>
<div class="page-reporte-ventas-articulo">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Ventas Por Artículo" class="elevation-1 px-5 py-3">

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
              <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-autocomplete v-model="model.id_sucursal" :items="sucursales" :hide-no-data="true"
                    :hide-selected="true"
                    item-text="nombre" item-value="_id" label="Sucursal"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" md="4" lg="4">
                    <v-autocomplete v-model="model.categoria" :items="categorias" :loading="isLoadingCategoria" 
                    :hide-no-data="true" :hide-selected="true" :rules="[valida_seleccion]"
                    item-text="nombre" multiple chips small-chips deletable-chips item-value="nombre" label="Categoría">                    
                    </v-autocomplete>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                    <v-autocomplete v-model="model.marca" :items="marcas" :loading="isLoadingMarca" multiple chips 
                      :rules="[valida_seleccion]"
                      small-chips deletable-chips hide-no-data hide-selected
                      item-text="nombre" item-value="nombre" label="Marca/Modelo" :hide-no-data="true" :hide-selected="true">
                      </v-autocomplete>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                  <v-autocomplete
                        v-model="model.id_articulo" :items="articulos" :hide-no-data="true"
                        :loading="isLoading" ref="selectarticulo"
                        :search-input.sync="search_articulo"                        
                        placeholder="Ingrese nombre de artículo para buscar"
                        prepend-icon="search" clearable
                        item-text="nombre" item-value="_id" 
                        :rules="[valida_seleccion]"                       
                        label="Artículo">
                    </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                  <v-autocomplete
                          v-model="model.id_empleado" :items="empleados" :hide-no-data="true"
                          :loading="isLoadingEmpleado"
                          :search-input.sync="search_empleado"
                          placeholder="Ingrese nombre del empleado para buscar"
                          prepend-icon="search"
                          item-text="nombre" item-value="name"                          
                          clearable
                          label="Buscar por Empleado">
                      </v-autocomplete>
              </v-col>              
            </v-row>
            <v-row>
              <v-col sm="6" md="6" lg="6">
                  <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha De" color="secondary" prepend-icon="event"
                        @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                    </template>

                    <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu1 = false">
                        Cancelar
                        </v-btn>
                    </v-date-picker>
                    </v-menu>
              </v-col>
              <v-col sm="6" md="6" lg="6">
                  <v-menu offset-x ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_a" label="Fecha A" color="secondary" prepend-icon="event"
                        @blur="model.fecha_a = parse_date(model.fecha_a)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                    </template>

                    <v-date-picker v-model="model.fecha_a" color="secondary" scrollable locale="es-mx" @input="menu2 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu2 = false">
                        Cancelar
                        </v-btn>
                    </v-date-picker>
                    </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-switch v-model="model.canceladas" label="Incluir canceladas" true-value="1" false-value="0"></v-switch>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="model = clean_model()">
          <v-icon>cancel</v-icon> Limpiar Filtros
        </v-btn>
        <v-btn color="success" @click.native="generar_reporte()">
          <v-icon>mdi-file-excel</v-icon> {{ "Generar Reporte Excel" }}
        </v-btn>
        <v-btn color="info" @click.native="consultar_ventas()">
          <v-icon>search</v-icon> Vista Previa
        </v-btn>
      </v-card-actions>

      <v-card-text>
        <div id="datagrid">

              <v-row>
                  <v-col style="text-align:right !important;"><h5>CANTIDAD TOTAL: {{ formatNumberDec(cantidad_total,2) }}</h5></v-col>                      
              </v-row>
              <v-row>
                  <v-col style="text-align:right !important;"><h5>TOTAL ARTÍCULO: $ {{ formatNumberDec(total_articulo,2) }}</h5></v-col>                      
              </v-row>
              
              <v-client-table ref="vuetable" :data="registros" :columns="columns" :options="options" class="elevation-2 pa-2">
                  <template slot="noResults">
                      <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                  </template>

                  <template slot="caja" slot-scope="props">
                      <div v-if="props.row.caja != null && props.row.caja!=''">{{ props.row.caja }}</div>
                      <div v-else>{{ props.row.caja_venta }}</div>
                  </template>   

                  <template slot="ticket" slot-scope="props">
                      <v-chip color="blue" label outlined x-small @click="show_detalle(props.row._id)">
                          {{ props.row.ticket }}
                      </v-chip>
                  </template>

                  <template slot="articulo_buscado.precio" slot-scope="props">
                      <div style="text-align:right !important;">${{ formatNumber(props.row.articulo_buscado.precio.toFixed(2))}}</div>
                  </template>
                  <template slot="articulo_buscado.cantidad" slot-scope="props">
                      <div style="text-align:right !important;">{{ props.row.articulo_buscado.cantidad }}</div>
                  </template>
                  <template slot="articulo_buscado.total" slot-scope="props">
                      <div style="text-align:right !important;">${{ formatNumber(props.row.articulo_buscado.total.toFixed(2))}}</div>
                  </template>

                  <!--template slot="actions" slot-scope="props" >
                      <v-speed-dial direction="left" open-on-hover >
                          <template v-slot:activator>
                              <v-btn x-small fab dark color="primary">
                                  <v-icon>reorder</v-icon>
                              </v-btn>
                          </template>

                          <v-btn x-small fab dark color="blue" @click.native="show_detalle(props.row._id)" title="Ver detalle" v-tippy>
                              <v-icon>list</v-icon>
                          </v-btn>
                          <v-btn x-small fab dark color="primary"
                              v-if="(['Finalizada', 'Cancelada', 'Facturada', 'Factura Global'].includes(props.row.estatus) && props.row.articulos_devolver.length>0 && props.row.tipo_venta=='Mayoreo')"
                              @click.native="imprimirOrdenEntrada(props.row._id)" title="Orden Devolución" v-tippy>
                              <v-icon>mdi-package-down</v-icon>
                          </v-btn>
                          <v-btn x-small fab dark color="success"
                              v-if="(['Finalizada', 'Cancelada', 'Facturada', 'Factura Global'].includes(props.row.estatus) && props.row.tipo_venta=='Mayoreo')"
                              @click.native="imprimirOrdenSalida(props.row._id)" title="Orden Salida" v-tippy>
                              <v-icon>mdi-package-up</v-icon>
                          </v-btn>

                      </v-speed-dial>
                  </template-->
              </v-client-table>
          </div>
      </v-card-text>


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal_detalle" max-width="70%" v-if="(model_detalle!='' && model_detalle!=undefined)">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">list</v-icon>&nbsp;
          <span class="subheading">
            <strong>Detalle de Venta</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_detalle = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs grow style="margin-bottom: 30px;" v-model="active_tab">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab key="datos">
            <v-card-title><b>Datos Generales</b></v-card-title>
          </v-tab>
          <v-tab key="articulos" v-show="(model_detalle.articulos!=undefined && model_detalle.articulos.length>0)">
            <v-card-title><b>Artículos</b></v-card-title>
          </v-tab>
          <v-tab key="devolucion" v-show="(model_detalle.articulos_devolver!=undefined && model_detalle.articulos_devolver.length>0)">
            <v-card-title><b>Artículos Devolución</b></v-card-title>
          </v-tab>

          <v-tab-item key="datos" style="margin: 20px;">
            <fieldset>
              <legend><strong>Información General</strong></legend>
              <v-form ref="form" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col>
                      <strong>Tipo Venta:</strong><br>
                      {{model_detalle.tipo_venta=='super'?'Menudeo':"Mayoreo"}}
                    </v-col>

                    <v-col>
                        <strong>#Ticket:</strong><br>
                      {{model_detalle.ticket}}
                    </v-col>

                    <v-col>
                        <strong>Sucursal:</strong><br>
                      {{model_detalle.sucursal.nombre}}
                    </v-col>

                    <v-col>
                        <strong>Fecha:</strong><br>
                      {{model_detalle.fecha.substring(0,16).replace("T"," ") }}
                    </v-col>
                  </v-row><br>

                  <v-row>
                      <v-col>
                        <strong>Caja:</strong><br>
                      {{model_detalle.caja }}
                    </v-col>

                    <v-col>
                      <strong>Empleado:</strong><br>
                      {{model_detalle.usuario}}
                    </v-col>

                    <v-col>
                        <strong>Cliente:</strong><br>
                      {{model_detalle.cliente.nombre}}
                    </v-col>

                    <v-col>
                        <strong>Observaciones:</strong><br>
                      {{model_detalle.observaciones}}
                    </v-col>
                  </v-row><br>

                   <v-row>
                    <v-col>
                        <strong>Estatus:</strong><br>
                      {{model_detalle.estatus}}
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col>
                          <strong>Subtotal:</strong><br>
                          {{ formatNumber(model_detalle.subtotal.toFixed(2)) }}
                        </v-col>
                        <v-col>
                            <strong>Impuestos:</strong><br>
                            {{ formatNumber(model_detalle.impuestos.toFixed(2)) }}
                        </v-col>
                      </v-row>                      
                    </v-col>                    

                    <v-col v-if="model_detalle.venta_cancelada!='' && model_detalle.venta_cancelada.articulos != undefined">
                        <v-row>
                          <v-col>
                            <strong>Total Cancelado:</strong><br>
                            {{ formatNumber(model_detalle.venta_cancelada.total.toFixed(2)) }}
                          </v-col>  
                          <v-col>
                            <strong>Total Actual:</strong><br>
                            {{ formatNumber(model_detalle.total.toFixed(2)) }}
                          </v-col>  
                          <v-col>
                            <strong>Diferencia:</strong><br>
                            {{ formatNumber((model_detalle.total - model_detalle.venta_cancelada.total).toFixed(2)) }}
                          </v-col>  
                        </v-row>
                        
                    </v-col>
                    <v-col v-else>
                        <strong>Total:</strong><br>
                        {{ formatNumber(model_detalle.total.toFixed(2)) }}
                    </v-col>
                  </v-row><br>

                  <v-row>
                    <v-col v-if="model_detalle.cancelado_por!=''">
                        <strong>Cancelación/Devolución Por:</strong><br>
                        {{model_detalle.cancelado_por.nombre}}
                    </v-col>
                    <v-col v-else>
                        <strong></strong><br>
                    </v-col>
                    <v-col v-if="model_detalle.fecha_salida!=''">
                      <strong>Fecha Salida:</strong><br>
                    {{model_detalle.fecha_salida.substring(0,16).replace("T"," ") }}
                    </v-col>
                    <v-col v-if="model_detalle.fecha_salida!=''">
                        <strong>Observaciones Salida:</strong><br>
                      {{model_detalle.observacion_salida}}
                    </v-col>
                    <v-col v-if="model_detalle.fecha_salida!=''">
                        <strong>Usuario Salida:</strong><br>
                        {{model_detalle.usuario_salida}}
                    </v-col>
                  </v-row><br>

                  <v-row v-if="model_detalle.articulos_devolver.length>0">
                    <v-col>
                        <strong>Estatus Devolución:</strong><br>
                        {{model_detalle.estatus_entrada}}
                    </v-col>
                    <v-col>
                      <strong>Fecha Entrada:</strong><br>
                    {{model_detalle.fecha_entrada.substring(0,16).replace("T"," ") }}
                    </v-col>

                    <v-col>
                        <strong>Observaciones Entrada:</strong><br>
                      {{model_detalle.observaciones_entrada}}
                    </v-col>

                    <v-col>
                        <strong>Usuario Entrada:</strong><br>
                        {{model_detalle.usuario_entrada}}
                    </v-col>
                  </v-row>


                </v-container>
              </v-form>
            </fieldset>
          </v-tab-item>

          <v-tab-item key="articulos" style="margin: 20px;">
            <fieldset>
              <legend><strong>Artículos:</strong></legend>
                <v-container grid-list-md>

                  <v-client-table :data="model_detalle.articulos" :columns="columnsArticulos" :options="optionsArticulos" class="elevation-2 pa-2">
                    <template slot="codigo_barras" slot-scope="props">
                        {{props.row.codigo_barras.join(", ")}}
                    </template>
                    <template slot="precio" slot-scope="props">
                        <div style="text-align:right !important;">{{formatNumber(parseFloat(props.row.precio).toFixed(2))}}</div>
                    </template>
                    <template slot="total" slot-scope="props">
                        <div style="text-align:right !important;">{{formatNumber(parseFloat(props.row.total).toFixed(2))}}</div>
                    </template>
                </v-client-table>

                </v-container>

            </fieldset><br>

            <fieldset v-if="model_detalle.articulos_cancelados!=undefined && model_detalle.articulos_cancelados.length>0">
              <legend><strong>Artículos cancelados/modificados en la venta:</strong></legend>
                <v-container grid-list-md>                  
                            
                  <v-client-table :data="model_detalle.articulos_cancelados" :columns="columnsArticulosCancelados" :options="optionsArticulos" class="elevation-2 pa-2">
                    <template slot="codigo_barras" slot-scope="props">
                        {{props.row.codigo_barras.join(", ")}}
                    </template>
                    <template slot="precio" slot-scope="props">
                        <div style="text-align:right !important;">{{formatNumber(parseFloat(props.row.precio).toFixed(2))}}</div>
                    </template>
                    <template slot="total" slot-scope="props">
                        <div style="text-align:right !important;">{{formatNumber(props.row.total.toFixed(2))}}</div>
                    </template>
                </v-client-table>

                </v-container>

            </fieldset><br>

            <fieldset v-if="model_detalle.venta_cancelada!='' && model_detalle.venta_cancelada.articulos != undefined">
              <legend><strong>Artículos originales en la venta ya que fue Cancelación/Devolución:</strong></legend>
                <v-container grid-list-md>

                  <v-client-table :data="model_detalle.venta_cancelada.articulos" :columns="columnsArticulos" :options="optionsArticulos" class="elevation-2 pa-2">
                    <template slot="codigo_barras" slot-scope="props">
                        {{props.row.codigo_barras.join(", ")}}
                    </template>
                    <template slot="precio" slot-scope="props">
                        <div style="text-align:right !important;">{{formatNumber(parseFloat(props.row.precio).toFixed(2))}}</div>
                    </template>
                    <template slot="total" slot-scope="props">
                        <div style="text-align:right !important;">{{formatNumber(props.row.total.toFixed(2))}}</div>
                    </template>
                </v-client-table>

                </v-container>

            </fieldset>
          </v-tab-item>

          <v-tab-item key="devolucion" style="margin: 20px;">
            <fieldset>
              <legend><strong>Artículos en Devolución:</strong></legend>
              <v-container grid-list-md>

                <v-client-table :data="model_detalle.articulos_devolver" :columns="columnsDevolucion" :options="optionsDevolucion" class="elevation-2 pa-2">
                    <template slot="codigo_barras" slot-scope="props">
                        {{props.row.codigo_barras.join(", ")}}
                    </template>
                </v-client-table>

              </v-container>
            </fieldset>
          </v-tab-item>

        </v-tabs>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_detalle = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
import Vue from "vue";
import "jsbarcode";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
export default {
  components: {
  },
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },
    search_articulo(val) {
        //Si no ha escrito 2 letras o mas
        if (val == null || val.length<2 ) return
        // Si todavía esta buscando
        if (this.isLoading) return
        this.isLoading = true
        //Se debe escapar los parentesis
        let data = {
                    "selector": {
                        "type":"articulos",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus": "Activo"
                    },                    
                };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        this.articulos = response.data.docs;
                    }else
                        this.articulos = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });
                }).then(()=>{ this.isLoading=false; });
      },
      search_proveedor(val){
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length < 2) return
      // Si todavía esta buscando
      if (this.isLoadingProveedor) return
      this.isLoadingProveedor = true
      let data = {
        "selector": {
          "type": "proveedores",
          "nombre": {
            "$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          },
          "estatus": "Activo"
        },
        "sort": ["nombre"],
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            this.proveedores = response.data.docs;
          } else
            this.proveedores = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los proveedores.",
            footer: ""
          });
        }).then(() => {
          this.isLoadingProveedor = false;
        });
    },
    search_empleado(val) {
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length<2 ) return
      // Si todavía esta buscando
      if (this.isLoadingEmpleado) return
      this.isLoadingEmpleado = true
      let data = {
                  "selector": {
                      "type":"user",
                      "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                      "estatus": "Activo"
                  },
                  // "sort": ["_id"],
              };

          window.axios
              .post(process.env.VUE_APP_COUCHDB_URL + '/_users/_find/',data)
              .then(response => {
                  if (response.data.docs.length > 0){
                      this.empleados = response.data.docs;
                  }else
                      this.empleados = [];
              })
              .catch(error => {
                  this.$swal({
                      type: "error",
                      title: "¡Operación no Permitida!",
                      text: "Ocurrió un error al obtener los empleados.",
                      footer: ""
                  });
              }).then(()=>{ this.isLoadingEmpleado=false; });
    },


  },
  mounted: function() {
    if (!this.verificaPermiso('t.ventas.articulo')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    this.getCategorias();
    this.get_sucursales();
    this.getMarcas();
  },
  data() {
    return {
        promedio_articulo:0,
        cantidad_total:0,
        total_articulo:0,
        active_tab:"datos",
        modal_detalle:false,
        //model_detalle:{"articulos":[],"articulos_devolver":[]},
        model_detalle:"",
        registros:[],
        columns: [
            "caja",
            "ticket",
            "sucursal.nombre",
            "fecha",
            "articulo_buscado.nombre",
            "articulo_buscado.precio",
            "articulo_buscado.cantidad",
            "articulo_buscado.total",
            "estatus",            
        ],
        options: {
          perPage: 10,
        pagination: {
          show: true
        },
            filterable: ["ticket","sucursal.nombre",
            "fecha","caja","articulo_buscado.nombre",
            "articulo_buscado.precio",
            "articulo_buscado.cantidad",
            "articulo_buscado.total","estatus"],
            sortable: ["ticket","sucursal.nombre",
            "fecha","caja","articulo_buscado.nombre",
            "articulo_buscado.precio",
            "articulo_buscado.cantidad",
            "articulo_buscado.total","estatus"],
            headings: {                
                "ticket":"#Ticket",
                "sucursal.nombre":"Sucursal",
                "caja":"Caja",
                "articulo_buscado.nombre":"Artículo",
                "articulo_buscado.precio":"Precio",
                "articulo_buscado.cantidad":"Cantidad",
                "articulo_buscado.total":"Importe",
                estatus: "Estatus",                
            },
        },
        columnsArticulosCancelados:["codigo_barras","nombre","descripcion","motivo_cancelacion","cantidad","precio","total"],
        columnsArticulos:["codigo_barras","nombre","descripcion","cantidad","precio","total"],
        optionsArticulos:{
            filterable: false,
            headings: {
            }
        },
        columnsDevolucion:["codigo_barras","nombre","descripcion","cantidad_devolver","estatus_entrada","cantidad_recibida"],
        optionsDevolucion:{
            filterable: false,
            headings: {
            }
        },
        sucursales:[],
        menu1:false,
        menu2:false,
        menu3:false,
        menu4:false,
      search_articulo:'',
      search_marca: null,
      tipos_precios: [],
      categorias:[],
      marcas:[],
      articulos:[],
      proveedores: [],
      articulos_proveedores:[],
      fab: [],
      isLoading: false,
      isLoadingEmpleado: false,
      search_empleado:'',
      empleados:[],
      isLoadingMarca: false,
      isLoadingCategoria: false,
      articulos_encontrados: [],
      id_articulo: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Reportes",
          disabled: true,
          href: ""
        },
        {
          text: "Ventas Por Artículo",
          disabled: true,
          href: ""
        }
      ],

      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    upperCaseInput(fieldName) {
      // Guarda la posición actual del cursor
      const input = event.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Transforma el valor del campo a mayúsculas
      const fieldValue = this.model[fieldName];
      const upperCaseValue = fieldValue.toUpperCase();

      // Actualiza el valor del campo en el modelo de datos
      this.model[fieldName] = upperCaseValue;

      // Actualiza el valor del input en la vista
      input.value = upperCaseValue;

      // Restablece la posición del cursor
      input.setSelectionRange(start, end);
    },
    get_sucursales: function () {
    let data = {
        "selector": {
            "type":"sucursales",
            "estatus": {
                "$eq": "Activo"
            }
        },
        "fields": [
            "nombre", "_id"
        ]
    };

    window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
            this.sucursales = [];
            if (response.data.docs.length > 0)
                this.sucursales = response.data.docs;
                let todas = {
                    _id: '0',
                    nombre: 'TODAS'
                };
                this.sucursales.unshift(todas);
        })

        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener las sucursales.",
                footer: ""
            });
        });
},
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    valida_time: function(time) {
      if(time!=null && time.toString()!=""){
            if(time.toString().length != 5)
                return "Hora no valida1";
            if(!time.match(/^\d{2}:(?:[0-5]\d)$/))
                return "Hora no valida2";
            var time_arr = time.split(":");
            if(time_arr.length!=2){
                return "Hora no valida3";
            }else{
                if(isNaN(time_arr[0]) || isNaN(time_arr[1])){
                    return "Hora no valida4";
                }
                if(parseInt(time_arr[0])<24 && parseInt(time_arr[1])<60)
                {
                    return true;
                } else{
                    return "Hora no valida5";
                }
            }
      }
      return true;

    },
    getCategorias: function() {
      this.isLoadingCategoria = true;
      let data = {
        "selector": {
          "type": "categorias",
          "estatus": "Activo"
        },
        "fields": [
          "nombre", "_id"
        ]
      };
      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.categorias = response.data.docs;
          else
            this.categorias = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las categorias.",
            footer: ""
          });
        }).then(()=>{
          this.isLoadingCategoria = false;
        });
    },
    getMarcas: function(el) {
      this.isLoadingMarca = true;
      let data = {
        "selector": {
          "type":"marcas",
          "estatus": "Activo",
        },
        "fields": [
          "nombre", "_id"
        ]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.marcas = response.data.docs;
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las marcas/modelos.",
            footer: ""
          });
        }).then(()=>{
          this.isLoadingMarca = false;
        });
    },
    clean_model: function() {
      return {
        id_sucursal:"0",
        categoria: [],
        marca: [],
        id_empleado:'',
        fecha_de: moment().format("YYYY-MM-DD"),
        fecha_a: moment().format("YYYY-MM-DD"),
        id_articulo: '',        
        canceladas: "0",

      };
    },
    valida_seleccion: function(val){
      if(this.model.categoria.length == 0 && this.model.marca.length == 0 & (this.model.id_articulo==undefined || this.model.id_articulo==null || this.model.id_articulo=="")){
        return "Seleccione al menos una categoría, marca ó artículo";
      }
      return true;
    },
    generar_reporte: function(){
        if(this.$refs.form.validate()){
        try {
                window.dialogLoader.show('Espere un momento por favor..');

                if (this.model.id_articulo == null)
                  this.model.id_articulo = '';
                if (this.model.id_empleado == null)
                  this.model.id_empleado = '';

                //console.log("MODEL: ", this.model);

                window.axios
                    .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_ventas_articulo/", this.model, {                      
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                    })
                    .then(response => {
                        var bytes = response.data;
                        let blob = new Blob([bytes], {
                                type: response.headers["content-type"]
                            }),
                            url = window.URL.createObjectURL(blob);

                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = "Ventas Artículo.xlsx";
                        a.click();
                        a.parentNode.removeChild(a);
                    })
                    .catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
                        footer: ""
                    });
                    }).then(()=>{
                    window.dialogLoader.hide();
                    });
            } catch (error) {
            console.log("ERRORS: ",error);
            }
        }
    },
    consultar_ventas:function(){
      if(this.$refs.form.validate()){
        window.dialogLoader.show('Espere un momento por favor..');
        this.promedio_articulo = 0;
        this.cantidad_total = 0;
        this.total_articulo = 0;        
        
        var estatus = ["Finalizada","Facturada", "Factura Global"];
        if(this.model.canceladas != undefined && this.model.canceladas != null && this.model.canceladas == "1"){        
            estatus.push("Cancelada");   
        }

        let data = {
            "selector": {
                "type":"ventas",
                "estatus": {"$in": estatus }, 
                "fecha": {
                    "$gte": this.model.fecha_de,
                    "$lte": this.model.fecha_a+ "T23:59:59.999"
                },          
            },
            "sort": [{"fecha":"desc"}],
            "use_index":"_design/d54236153ba037bbce44e2a6f12dbe4ebd9e9a84",
            "fields": ["_id", "tipo_venta",
                "ticket", "sucursal", "fecha","caja_venta","usuario_venta","caja","usuario", "cliente",
                "articulos", 
                "total","estatus"
            ]
        };

        if(this.model.id_sucursal != undefined && this.model.id_sucursal != null && this.model.id_sucursal != "0"){
            data["selector"]["sucursal._id"] = this.model.id_sucursal;
        }
        if(this.model.id_cliente != undefined && this.model.id_cliente != null && this.model.id_cliente != ""){
            data["selector"]["cliente._id"] = this.model.id_cliente;
        }
        if(this.model.id_empleado != undefined && this.model.id_empleado != null && this.model.id_empleado != ""){
            data["selector"]["usuario_venta_id"] = this.model.id_empleado;
        }
        if(this.model.id_articulo != undefined && this.model.id_articulo != null && this.model.id_articulo != ""){
            data["selector"]["articulos"] = {"$elemMatch": {"_id": this.model.id_articulo }};
        } else {
          if(this.model.categoria != undefined && this.model.categoria != null && this.model.categoria.length > 0){
              data["selector"]["articulos"] = {"$elemMatch": {"categoria": {"$elemMatch": {"$in": this.model.categoria}} }};
          }
          if(this.model.marca != undefined && this.model.marca != null && this.model.marca.length > 0){
              if(data["selector"]["articulos"]){//Si trae filtrod e categoria se agrega el de marca, sino solo el de marca
                data["selector"]["articulos"]["$elemMatch"]["marca"] = {"$in": this.model.marca} ;
              } else {
                data["selector"]["articulos"] = {"$elemMatch": {"marca": {"$in": this.model.marca} }};
              }
              
          }
        }
        
             
        
        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                if (response.data.docs.length > 0){
                    this.registros = [];
                    var records = response.data.docs;
                    
                    for(var key in records){
                        var venta = records[key];                        

                        venta["tipo_venta"] = venta["tipo_venta"]=="super" ? "Menudeo" : "Mayoreo";
                        venta["fecha"] = venta["fecha"].substring(0,16).replace("T"," ");
                        /*venta["devolucion"] = "No";
                        if(venta["articulos_devolver"] == undefined){
                            venta["articulos_devolver"] = [];
                        } 
                        if(venta["articulos_cancelados"] && venta["articulos_cancelados"].length>0){
                          venta["cancelados"] = "Si";
                        } else {
                          venta["cancelados"] = "No";
                        }
                        if(venta["articulos_devolver"].length>0){
                            venta["devolucion"] = "Si";
                        } else if (venta["estatus"]!="Cancelada" && venta["venta_cancelada"]!= undefined && venta["venta_cancelada"]!= ""){ 
                          try {
                            if (venta["venta_cancelada"]["articulos"]!=undefined && venta["venta_cancelada"]["articulos"].length>0){
                              //Si es una venta finalizada de menudeo, checamos si fue una devolucion/cancelacion
                              venta["devolucion"] = "Si";
                            }
                          } catch (error) {
                            
                          }                      
                        }*/

                        for(var ka in venta['articulos']){
                          var articulo = venta['articulos'][ka];
                          if(this.model.id_articulo != undefined && this.model.id_articulo != null && this.model.id_articulo != "" && articulo._id != this.model.id_articulo){
                              continue;
                          }
                          if(this.model.categoria != undefined && this.model.categoria != null && this.model.categoria.length > 0){
                            var cumple = 0;
                            for(var kc in articulo.categoria){
                              if(this.model.categoria.includes(articulo.categoria[kc]))
                                cumple = 1;
                            }
                            if(cumple == 0)
                              continue;
                          }
                          if(this.model.marca != undefined && this.model.marca != null && this.model.marca.length > 0 && !this.model.marca.includes(articulo.marca)){
                              continue;
                          }
                          
                          
                          venta["articulo_buscado"] = articulo;
                          var copy_venta = {
                            "_id": venta["_id"], 
                            "tipo_venta": venta["tipo_venta"],
                            "ticket": venta["ticket"], 
                            "sucursal": venta["sucursal"], 
                            "fecha": venta["fecha"],
                            "caja_venta": venta["caja_venta"],                            
                            "caja": venta["caja"],                                                        
                            "estatus": venta["estatus"],
                            articulo_buscado: {
                              nombre: articulo.nombre,
                              precio: articulo.precio,
                              cantidad: articulo.cantidad,
                              total: articulo.total,
                            },                            
                          };                         
                          this.registros.push(copy_venta);
                          this.cantidad_total += parseFloat(articulo['cantidad']);
                          this.total_articulo += parseFloat(articulo['total']);
                          
                        }
                        //this.promedio_articulo = this.total_articulo / this.cantidad_total;

                                           
                    }
                }else
                    this.registros = [];
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al consultar las ventas. Intente nuevamente.",
                    footer: ""
                });
            }).then(()=>{
                window.dialogLoader.hide();
            });
      }

    },
    formatNumber: function (x) {
          if (x == undefined) return 0;
          x = x.toString();
          var pattern = /(-?\d+)(\d{3})/;
          while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
          return x;
      },
      formatNumberDec: function(numero, decimales){
            if (numero == undefined || numero == null || numero == "")
                numero = 0;
            numero = Number(numero);
            if (isNaN(numero)){
                return "";
            } else
                return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        },

    imprimirOrdenEntrada: function(id_venta){
         window.dialogLoader.show('Espere un momento por favor..');
        var self = this;

        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_venta)
        .then(response => {
            this.model_detalle = response.data;

            var img = document.createElement("img");
            JsBarcode(img, this.model_detalle["ticket"], {format: "CODE128"});

            var columns = [
                {title: "Cantidad", dataKey: "cantidad"},
                {title: "Artículo", dataKey: "nombre_articulo"},
                {title: "Código", dataKey: "codigo_articulo"},
                {title: "Descripción", dataKey: "descripcion"},
            ];

            //setTimeout(function(){
                var doc = new jsPDF('p', 'mm', 'letter');

                doc.setFontSize(14);
                doc.setFont('Helvetica','bold');
                doc.text('LA BODEGUITA DE GUADALUPE', 120, 23, {align:'center'});
                doc.setFontSize(9);
                doc.text('RFC: BGU960207S5A', 120, 30, {align:'center'});
                doc.setFontSize(12);
                doc.text('Orden de Entrada', 15, 40);
                doc.setFontSize(8);
                doc.setFont('Helvetica','normal');
                doc.text('Devolución/Cancelación de venta', 15, 45);
                doc.text('Cliente: '+this.model_detalle["cliente"]["nombre"], 15, 50);
                doc.text('Sucursal: '+this.model_detalle["sucursal"]["nombre"], 15, 55);
                doc.text('Fecha Elaboración: '+ this.parse_date_time_mx(this.model_detalle["fecha"]), 15, 60);
                doc.text('Fecha Impresión: '+ this.parse_date_time_mx(window.moment()), 15, 65);
                doc.text('Le Atendió: '+this.model_detalle["usuario_venta"], 15, 70);
                doc.addImage(img.src, 'PNG', 145, 40, 50, 17);

                doc.setFontSize(8);
                doc.autoTable(columns, this.model_detalle["articulos_devolver"], {
                    startY: 75,
                    headStyles:{fontSize:8},
                    bodyStyles:{fontSize:8},
                    columnStyles: { codigo_articulo:{fontSize:6}, cantidad: { halign: 'right' } },

                });

                doc.save(this.model_detalle["ticket"]+"_orden_devolucion.pdf");
                window.dialogLoader.hide();
            //},500);

        }) .catch(error => {
            console.log(error);
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar la venta. Intente nuevamente.",
                footer: ""
            });
            window.dialogLoader.hide();
        });
    },
    parse_date_time_mx(date) {
      if (!date) return null;
      return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },

    imprimirOrdenSalida: function(id_venta){
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;

        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_venta)
        .then(response => {
            this.model_detalle = response.data;
            for(var key in this.model_detalle["articulos"]){
                this.model_detalle["articulos"][key]["precio"] = this.model_detalle["articulos"][key]["precio"].toFixed(2);
                this.model_detalle["articulos"][key]["subtotal"] = this.model_detalle["articulos"][key]["subtotal"].toFixed(2);
            }

            var img = document.createElement("img");
            JsBarcode(img, this.model_detalle["ticket"], {format: "CODE128"});

            var columns = [
                {title: "Cantidad", dataKey: "cantidad"},
                {title: "Artículo", dataKey: "nombre_articulo"},
                {title: "Código", dataKey: "codigo_articulo"},
                {title: "Descripción", dataKey: "descripcion"},
                {title: "P. Unitario", dataKey: "precio"},
                {title: "Importe", dataKey: "subtotal"},
            ];

            //setTimeout(function(){
                var doc = new jsPDF('p', 'mm', 'letter');

                doc.setFontSize(14);
                doc.setFont('Helvetica','bold');
                doc.text('LA BODEGUITA DE GUADALUPE', 120, 23, {align:'center'});
                doc.setFontSize(9);
                doc.text('RFC: BGU960207S5A', 120, 30, {align:'center'});
                doc.setFontSize(12);
                doc.text('Orden de Salida', 15, 40);
                doc.setFontSize(8);
                doc.setFont('Helvetica','normal');
                doc.text('Cliente: '+this.model_detalle["cliente"]["nombre"], 15, 45);
                doc.text('Sucursal: '+this.model_detalle["sucursal"]["nombre"], 15, 50);
                doc.text('Fecha Elaboración: '+ this.parse_date_time_mx(this.model_detalle["fecha"]), 15, 55);
                doc.text('Fecha Impresión: '+ this.parse_date_time_mx(window.moment()), 15, 60);
                doc.text('Le Atendió: '+this.model_detalle["usuario_venta"], 15, 65);
                doc.addImage(img.src, 'PNG', 145, 40, 50, 17);

                doc.setFontSize(8);
                doc.autoTable(columns, this.model_detalle["articulos"], {
                    startY: 70,
                    headStyles:{fillColor:[26,189,156],fontSize:8},
                    bodyStyles:{fontSize:8},
                    columnStyles: { codigo_articulo:{fontSize:6},precio: { halign: 'right' },subtotal: { halign: 'right' }, cantidad: { halign: 'right' } },
                    foot: [[
                            {content: window.funciones.numeroALetras(this.model_detalle["total"], ''), rowSpan:3, colSpan:4, styles:{halign:'center',valign:'middle'}},
                            {content:"SUBTOTAL:", styles:{halign:'right'}},
                            {content:this.model_detalle["subtotal"].toFixed(2), styles:{halign:'right'}},
                        ],[
                            {content:"IMPUESTOS:", styles:{halign:'right'}},
                            {content:this.model_detalle["impuestos"].toFixed(2), styles:{halign:'right'}},

                        ],
                        [
                            {content:"TOTAL:", styles:{halign:'right'}},
                            {content:this.model_detalle["total"].toFixed(2), styles:{halign:'right'}},

                        ]],
                    footStyles:{fillColor:[234,250,241],textColor:"black", fontSize:8},
                });

                let finalY = doc.lastAutoTable.finalY; // The y position on the page
                doc.text('Gracias por su compra', 15, finalY+10);
                doc.text('Observaciones: '+this.model_detalle["observaciones"], 15, finalY+20);

                doc.setFont('Helvetica','bold');
                doc.text('____________________________________________________', 110, finalY+30, {align:'center'});
                doc.text('Firma de conformidad', 110, finalY+35, {align:'center'});

                doc.save(this.model_detalle["ticket"]+"_orden_salida.pdf");
                window.dialogLoader.hide();
            //},500);

        }) .catch(error => {
            console.log(error);
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar la venta. Intente nuevamente.",
                footer: ""
            });
            window.dialogLoader.hide();
        });

    },
    show_detalle:function(id_venta){
        window.dialogLoader.show('Espere un momento por favor..');

        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_venta)
        .then(response => {
            this.model_detalle = response.data;

            if(!this.model_detalle.cancelado_por.hasOwnProperty('nombre') )
                this.model_detalle.cancelado_por = "";

            if(this.model_detalle.articulos_devolver == undefined){
                this.model_detalle["articulos_devolver"] = [];
            }
            this.active_tab = "datos";
            this.modal_detalle = true;
        }) .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al consultar la venta. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            window.dialogLoader.hide();
        });
      },



  }
}
</script>
